<template>
  <Details
    title="bundle"
    resource="bundles"
    ref="details"
    :base-path="basePath"
    data-provider="$authDataProvider"
    title-template="edit bundle {name}"
    breadcrumb-template="{name}"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>bundle details</template>
        <template v-slot:text>Edit the basic information about the bundle.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <BundleForm
        :initialValues="prepareInitialValues(detailsProps.record)"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ActionsMixin from "@/mixins/ActionsMixin";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import BundleForm from "@/views/auth/applications/bundles/BundleForm";
  import { ROLES } from '@/components/auth/constants';

  export default {
    name: "BundleEdit",
    components: {
      Details,
      DetailsSidePanel,
      BundleForm,
    },
    mixins: [
      ConfirmationMixin,
      ModalNavigation,
      ValidatorMixin,
      ActionsMixin,
      NotifyMixin,
    ],
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
      bundleId() {
        return this.$route.params.id;
      },
    },
    methods: {
      prepareInitialValues(record) {
        // mappedRoles: {[metaroleId]: { [appId]: roleId }}
        const mappedRoles = record.metaroles?.reduce((acc, { id, roles }) => {
          acc[id] = {};
          roles.forEach(({ appId, id: roleId }) => {
            acc[id][appId] = roleId || ROLES.NO_ROLE;
          });
          return acc;
        }, {}) || {};

        return {
          ...record,
          mappedRoles,
        }
      },
      deleteBundle() {
        this.$authDataProvider.delete('bundles', { id: this.bundleId })
          .then(() => {
            this.notifySuccess('bundle deleted');
            this.$refs.details.redirectToList();
          })
          .catch(error => this.notifyError(error.message))
      }
    },
    mounted() {
      this.$watch('$refs.details.record', function (record) {
        const { apps, deletedAt} = record;

        if (!deletedAt && apps?.length === 0) {
          this.addConfirmAction(
              {
                id: 'auth-delete-bundle',
                title: 'delete bundle',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this bundle?',
                confirmationType: 'warning',
                confirmBtnText: 'yes',
                cancelBtnText: 'no'
              },
              this.deleteBundle
          );
        }
      });
    }
  }
</script>

<style scoped>

</style>
